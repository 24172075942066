<template>
  <main>
    <content-page-header>
      <div class="hdg-tab">
        <common-page-title title="新規会員登録" />
        <tab-nation now-page="1" />
      </div>
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <div class="apply-register-panel">
          <license-infomation license-type="premium"/>
          <div class="border-t border-t--decoration">
            <section>
              <common-block-title
                title="アカウント作成"
                sub-title=""
                class-list="ja"
              />
              <form id="applyRegisterForm" class="apply-register-form">
                <label class="form-function">
                  <div class="form-input-label">
                    メールアドレス<sup class="type-required">*</sup>
                  </div>
                  <div class="w-full">
                    <input
                      type="email"
                      placeholder="メールアドレス"
                      v-model="state.email"
                    />
                  </div>
                </label>
                <label class="form-function">
                  <div class="form-input-label">
                    メールアドレス確認<sup class="type-required">*</sup>
                  </div>
                  <div class="w-full">
                    <input
                      type="email"
                      placeholder="メールアドレス"
                      v-model="state.confirmEmail"
                    />
                    <div
                      class="error-message"
                      v-for="(error, index) in state.errors.email"
                      :key="index"
                    >
                      <icon name="error" class="icon" />{{ error }}
                    </div>
                    <div v-if="state.errorEmailCheck" class="error-message">
                      <icon name="error" class="icon" />{{
                        state.errorEmailCheck
                      }}
                    </div>
                  </div>
                </label>
              </form>
            </section>
          </div>
          <div class="submit-area">
            <button
              class="submit-area-button submit-area-button--cancel"
              @click="$router.back()"
            >
              Cancel
            </button>
            <button
              class="submit-area-button submit-area-button--save"
              :disabled="!(state.email && state.confirmEmail) || state.isSent"
              @click="submit"
            >
              Save
            </button>
          </div>
        </div>
      </panel-body>
    </div>
  </main>
</template>

<script>
import { defineComponent, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import http from "@/utils/http";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import TabNation from "@/views/apply/components/tabNation.vue";
import licenseInfomation from "@/views/apply/components/licenseInfomation.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "applySend",

  components: {
    contentPageHeader,
    CommonPageTitle,
    TabNation,
    licenseInfomation,
    CommonBlockTitle,
    PanelBody,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      email: "",
      confirmEmail: "",
      errors: [],
      errorEmailCheck: "",
      isSent: false,
    });
    
    const isLogin = computed(() => store.state.user.isLogin);

    // A8アフィリエイト
    const a8Id = computed(() => store.state.a8Id);

    window.scrollTo(0, 0);

    const submit = () => {
      state.errors = [];
      state.errorEmailCheck = "";
      state.isSent = true;
      http
        .post("/api/temporary-users/send", {
          email: state.email,
          email_confirmation: state.confirmEmail,
          service_plan_id: 1,
          a8_id: a8Id.value,
        })
        .then(() => {
          store.state.user.signUp.email = state.email;
          store.state.user.signUp.confirmEmail = state.confirmEmail;
          store.state.user.signUp.tabNation =
            store.state.user.signUp.tabNation + 1;
          router.push("/apply/send/complete");
        })
        .catch((error) => {
          if (error.config && error.response && error.response.status === 400) {
            state.errors = error.response.data.errors;
          } else if (
            error.config &&
            error.response &&
            error.response.status === 500
          ) {
            state.errorEmailCheck = error.response.data.message;
          }
          state.isSent = false;
        });
    };

    onMounted(async () => {
      if (isLogin.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isLogin,
      async () => {
        if (isLogin.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      store,
      state,
      submit,
    };
  },
});
</script>

<style lang="scss" scoped>
.hdg-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(lg) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include mq(md) {
    display: block;
  }
}

.content-body {
  position: relative;
  margin-top: -221px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;
  @include mq(md) {
    margin-top: -590px;
  }
  @include mq(sm) {
    margin-top: -100px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
.panel-body {
  margin-bottom: 50px;
  padding: 65px;
  background-color: $white;
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  backdrop-filter: blur(10px);
  @include mq(sm) {
    padding: 60px 30px;
  }
}

.apply-register-form {
  padding-top: 65px;
}
.error-message {
  margin-top: 1em;
}
</style>